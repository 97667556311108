
const propressTotalTime=function(samplingNum,divisorsNum){
  let samplingNumCoefficient=samplingNum==10000?0.8:samplingNum==30000?2.2:samplingNum==50000?3.4:1;//抽样点数计算的系数
  let divisorsNumCoefficient=10;//因子数量系数
  let timeCoefficient=5.5
  if(divisorsNum<=5){
    divisorsNumCoefficient=5
    timeCoefficient=5.5
  }else if(divisorsNum>5&&divisorsNum<=10){
    divisorsNumCoefficient=10
    timeCoefficient=5.5
  }else if(divisorsNum>10&&divisorsNum<=15){
    divisorsNumCoefficient=15
    timeCoefficient=4.5
  }else if(divisorsNum>15&&divisorsNum<=20){
    divisorsNumCoefficient=20
    timeCoefficient=4.5
  }else if(divisorsNum>20&&divisorsNum<=25){
    divisorsNumCoefficient=25
    timeCoefficient=7
  }else if(divisorsNum>25&&divisorsNum<=30){
    divisorsNumCoefficient=30
    timeCoefficient=7
  }else if(divisorsNum>30&&divisorsNum<=35){
    divisorsNumCoefficient=35
    timeCoefficient=8
  }else if(divisorsNum>35&&divisorsNum<=40){
    divisorsNumCoefficient=40
    timeCoefficient=9.5
  }
  return Math.ceil(samplingNumCoefficient*divisorsNumCoefficient*timeCoefficient) 
}
export default propressTotalTime