<template>
  <div>
    <div class="progress">
      <div :class="{'active':step==1,'complate':step>1}">
        <div class="dot"></div>
        <div class="text">获取数据</div>
      </div>
      <div  :class="{'active':step==2,'complate':step>2}">
        <div class="dot"></div>
        <div class="text">特征构建</div>
      </div>
      <div :class="{'active':step==3,'complate':step>3}">
        <div class="dot"></div>
        <div class="text">模型构建</div>
      </div>
      <div :class="{'active':step==4,'complate':step>4}">
        <div class="dot"></div>
        <div class="text">模型估计</div>
      </div>
      <div :class="{'active':step==5,'complate':step>5}">
        <div class="dot"></div>
        <div class="text">生成预测</div>
      </div>
    </div>
    <div class="flex-row align-items-center margin-top-20">
      <el-button size="mini" @click="cancel">中止运算</el-button>
      <div class="color-sub margin-left-10">点击中止运算，会停止模型计算。</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pageTopPrice',
  props:{
        totalSeconds:{
            type:Number,
            default:60
        },
        finished:{
            type:Boolean,
            default:false
        }
    },
  data() {
    return {
        step:0,
        pastSeconds:0,
        progressTimer:null
    }
  },
  methods:{
    cancel (){
      this.$confirm('确定要终止模型计算吗？终止后无计算结果。',{
        customClass:"custom-msgbox",
        type: 'warning'
      })
      .then(() => {
        this.$emit('cancel')
      })
     .catch(() => {});
      
    },
    progess(){
      this.progressTimer=setInterval(()=>{
        if(this.finished){
           clearTimeout(this.progressTimer)
           this.step=5
           return false
        }
        if(this.pastSeconds<(this.totalSeconds-1)){
          this.pastSeconds+=1
        }
        if(this.pastSeconds<=2){
          this.step=1
        }else if(this.pastSeconds>2&&this.pastSeconds<=4){
          this.step=2
        }else if(this.pastSeconds>4&&this.pastSeconds<=6){
          this.step=3
        }else if(this.pastSeconds>6&&this.pastSeconds<=(this.totalSeconds-6)*0.7){
          this.step=4
        }else if(this.pastSeconds>(this.totalSeconds-6)*0.7){
          this.step=5
        }
      },1000)
    }
  },
  created(){
    this.progess()
  },
  mounted(){
    
  },
  destroyed(){
    clearTimeout(this.progressTimer)
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  .progress{
    display: flex;
    justify-content: space-between;
    .dot{
      position: relative;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      @include themeify{background: themed('borderColor');};
    }
    .text{
      margin-top:20px
    }
    .complate{
      color:#004ea3;
      .dot{
        background: #004ea3;
      }
    }
    .active{
      color:#3870ff;
      .dot::before{
        position:absolute;
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 2px solid #3870ff;
        border-bottom-color: transparent;
        @include themeify{background: themed('columnBackground');};
        transform-origin: center;
        left:-6px;
        top:-6px;
        -webkit-animation: rotate 1.5s infinite linear;
        animation: rotate 1.5s infinite linear;
      }
      .dot::after{
        position:absolute;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: #3870ff;
        border-radius: 100%;
        left:0;
        top:0;
      }
    }
  }
  @-webkit-keyframes rotate { 100% { -webkit-transform: rotate(360deg) }}
  @keyframes rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}
  .progress>div{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    transition: all 0.5s ease 0s;
  }
  .progress>div::before{
    content: "";
    position: absolute;
    height: 2px;
    top:6px;
    left: 0;
    right: 0;
    @include themeify{background: themed('borderColor');};
  }
  .progress>div.complate::before{
    background: #004ea3!important;
    @include themeify{
      background:linear-gradient(90deg,#004ea3,themed('borderColor'));
    };
  }
  .progress>div.active::before{
    @include themeify{
      background:linear-gradient(90deg,#004ea3,themed('borderColor'));
    };
  }
</style>
