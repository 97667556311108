<template>
  <div>
    <div class="padding-20">
      <div class="flex-row  align-items-center justify-between margin-bottom-20">
        <div class="flex-row  align-items-center">
          <i class="el-icon-arrow-left font-size-24 bold margin-right-10 link" @click="$router.go(-1)"></i>
          <div v-if="targetInfo">
            <el-popover placement="right-start" width="400" trigger="hover">
              <div class="font-size-12 line-height-large">
                <div  v-if="targetInfo.desc">
                  <div><span class="color-sub">数据名称：</span>{{ targetInfo.desc.name }}</div>
                  <div><span class="color-sub">数据单位：</span>{{ targetInfo.desc.unit }}</div>
                  <div><span class="color-sub">市场名称：</span>{{ targetInfo.desc.market }}</div>
                  <div><span class="color-sub">规格牌号：</span>{{ targetInfo.desc.dataModel }}</div>
                  <div><span class="color-sub">更新频率：</span>{{ targetInfo.desc.frequency }}</div>
                  <div><span class="color-sub">统计日期：</span>{{ targetInfo.desc.time }}</div>
                  <div><span class="color-sub">样本说明：</span>{{ targetInfo.desc.explain }}</div>
                  <div><span class="color-sub">方法论：</span>{{ targetInfo.desc.func }}</div>
                  <div><span class="color-sub">数据来源：</span>{{ targetInfo.desc.dataSource }}</div>
                </div>
              </div>
              <span slot="reference">
                  <span class="link font-size-20">{{targetInfo.customDivisorName}}</span>
              </span>
            </el-popover>
          </div>
        </div>
        <div class="flex-row" v-if="modelInfo.customModelId">
          <div class="flex-row  align-items-center border-right padding-right-20 margin-right-20" v-if="modelInfo.isCustom">
            <span class="margin-right-5 color-sub">启用预测报告</span>
            <el-switch v-model="modelInfo.isReport" :active-value="1" :inactive-value="0" @change="updateReportStatus"></el-switch>
          </div>
          <div>
            <el-popover placement="bottom" trigger="hover" :key="getRunIdLoading">
              <div>
                <div class="link" @click="gotoScene">情景模拟</div>
                <div class="link margin-top-10" @click="getRunId">模型自估</div>
              </div>
              <span slot="reference">
                <el-button size="mini">更新预测</el-button>
              </span>
            </el-popover>
          </div>
          <div class="border-left padding-left-20 margin-left-20">
            <el-button size="mini" @click="gotoHistory">查看历史</el-button>
            <el-button size="mini" @click="delModel">删除</el-button>
          </div>
        </div>
      </div>
      
      <el-row :gutter="20" type="flex">
        <el-col :span="24">
          <div class="column flex-row" v-loading="modelInfoLoading">
              <div>
                <p class="color-sub font-size-12 text-nowrap">方案名称</p>
                <p class="margin-top-5">{{modelInfo.customModelName}}</p>
              </div>
              <div class="border-right margin-tb-5 margin-lr-30"></div>
              <div>
                <p class="color-sub font-size-12 text-nowrap">预测周期</p>
                <p class="margin-top-5">{{modelInfo.frequency}}期</p>
              </div>
              <div class="border-right margin-tb-5 margin-lr-30"></div>
              <div>
                <p class="color-sub font-size-12 text-nowrap">模型框架</p>
                <p class="margin-top-5">{{modelInfo.agriName}}</p>
              </div>
              <div class="border-right margin-tb-5 margin-lr-30"></div>
              <div>
                <p class="color-sub font-size-12 text-nowrap">历史估计周期</p>
                <p class="margin-top-5"><span>{{modelInfo.factorStart | dateFormat('yyyy-MM')}}</span>至今</p>
              </div>
              <div class="border-right margin-tb-5 margin-lr-30"></div>
              <div>
                <p class="color-sub font-size-12 text-nowrap">创建时间</p>
                <p class="margin-top-5">{{modelInfo.addTime | dateFormat('yyyy-MM-dd hh:mm')}}</p>
              </div>
              <div class="border-right margin-tb-5 margin-lr-30"></div>
              <div>
                <p class="color-sub font-size-12 text-nowrap">预测更新</p>
                <p class="margin-top-5">{{modelInfo.factorUpdate | dateFormat('yyyy-MM-dd hh:mm')}}</p>
              </div>
            
          </div>
        </el-col>
        <el-col :span="q.modelFreq=='month'?12:24">
          <div class="column flex-column" v-loading="modelForecast.loading">
            <div class="flex-row align-items-start justify-between">
              <div>
                  <div>
                    <span>预测结果</span>
                  </div>
                  <div class="margin-top-5 font-size-12">
                    <span class="color-sub">
                      预测周期：<template v-if="modelForecast.data.startTime||modelForecast.data.endTime">{{modelForecast.data.startTime | dateFormat(this.q.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}至{{modelForecast.data.endTime | dateFormat(this.q.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}</template>
                    </span>
                  </div>
              </div>
              <el-tooltip effect="dark" content="展示数据列表" placement="top">
                <div class="link-text" @click="modelForecast.dataViewVisible=true">数据详情</div>
              </el-tooltip>
            </div>
            <Chart :option="modelForecastChart" class="flex-auto"></Chart>
          </div>
          <el-dialog custom-class="custom-dialog" :visible.sync="modelForecast.dataViewVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :append-to-body="true" top="50px">
            <div slot="title">
              <div class="flex-row align-items-center justify-between margin-right-40">
                <div class="el-dialog__title">预测结果-数据详情</div>
              </div>
            </div>
            <el-table
              v-if="modelForecast.data.predicValue"
              :height="$root.docHeight-220"
              :data="modelForecast.data.predicValue"
              size="medium"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="150"></el-empty></div>
              <el-table-column label="时间">
                <template slot-scope="props">{{props.row.dataCycle}}</template>
              </el-table-column>
              <el-table-column label="模型自估预测">
                <template slot-scope="props">{{props.row.value | numFormat(2,'-')}}</template>
              </el-table-column>
            </el-table>
          </el-dialog>
        </el-col>
        <el-col  :span="12" v-if="q.modelFreq=='month'">
          <div class="column flex-column" v-loading="trustGroup.loading">
            <div class="flex-row align-items-start justify-between">
              <div>
                  <div>
                    <span>预测分布</span>
                  </div>
                  <div class="margin-top-5 font-size-12">
                    <span class="color-sub">预测周期：{{ trustGroup.data.dateCycle | dateFormat(this.q.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM') }}</span>
                  </div>
              </div>
              <div class="flex-row align-items-center">
                <el-select v-model="trustGroup.freq" size="mini" style="width:100px;margin-right:20px" @change="getTrustGroupValue">
                  <el-option v-for="(item,index) in 12" :key="index" :value="item" :label="`未来${item}期`"></el-option>
                </el-select>
                <el-tooltip effect="dark" content="展示数据列表" placement="top">
                  <div class="link-text text-nowrap" @click="trustGroup.dataViewVisible=true">数据详情</div>
                </el-tooltip>
              </div>
              
            </div>
            <Chart :option="trustGroupChart" class="flex-auto"></Chart>
          </div>
          <el-dialog custom-class="custom-dialog" :visible.sync="trustGroup.dataViewVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :append-to-body="true" top="50px">
            <div slot="title">
              <div class="flex-row align-items-center justify-between margin-right-40">
                <div class="el-dialog__title">预测分布-数据详情</div>
                  <div class="flex-row align-items-center">
                  <el-select v-model="trustGroup.freq" size="mini" style="width:100px;margin-right:20px" @change="getTrustGroupValue">
                    <el-option v-for="(item,index) in 12" :key="index" :value="item" :label="`未来${item}期`"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <el-table v-if="trustGroup.dataViewVisible"
              :height="$root.docHeight-220"
              :data="trustGroup.data.dataList"
              size="medium"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="150"></el-empty></div>
              <el-table-column label="分布区间">
                <template slot-scope="props">{{props.row.interval}}</template>
              </el-table-column>
              <el-table-column label="分布数量">
                <template slot-scope="props">{{props.row.cnt}}</template>
              </el-table-column>
              <el-table-column label="分布概率">
                <template slot-scope="props">{{props.row.probability | numFormat(2,'-')}}%</template>
              </el-table-column>
            </el-table>
          </el-dialog>
        </el-col>
        <el-col :span="12">
          <div class="column flex-column" v-loading="divisors.loading">
            <div class="flex-row align-items-start justify-between">
              <div>
                <div>模型构成</div>
                <div class="margin-top-5 font-size-12">
                  <span class="color-sub">
                    创建时间：
                    {{modelInfo.addTime | dateFormat('yyyy-MM-dd hh:mm','-')}}
                  </span>
                </div>
              </div>
            </div>
            <div class="margin-top-10">
              <el-table
                ref="divisorsTable"
                :max-height="350"
                size="medium"
                :data="divisors.data">
                <div slot="empty"><el-empty :image-size="150"></el-empty></div>
                <el-table-column
                  min-width="150"
                  :show-overflow-tooltip="false"
                  label="因子">
                  <template slot-scope="props">
                    <el-popover placement="right-start" width="350" trigger="hover">
                      <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">
                        <div v-for="(item,index) in props.row.children" :key="index" :class="{'padding-top-10':index>0,'margin-top-10':index>0,'border-top':index>0}">
                          <div><span class="color-sub">数据名称：</span>{{item.factorName}}</div>
                          <div><span class="color-sub">数据单位：</span>{{item.unit}}</div>
                          <div><span class="color-sub">数据类型：</span>{{item.typeName}}</div>
                          <div><span class="color-sub">更新频率：</span>{{item.modelFreq}}</div>
                          <div><span class="color-sub">统计日期：</span>{{item.startDate}}-{{item.endDate}}</div>
                          <div><span class="color-sub">方法论：</span>{{item.func}}</div>
                          <div><span class="color-sub">数据来源：</span>{{item.dataSource}}</div>
                        </div>
                      </div>

                      <span slot="reference" class="display-inline-block">
                        <span class="text-overflow-ellipsis-block" @click="getDivisorChart(props.row)">
                          <span class="link-text">{{props.row.customDivisorName}}</span>
                        </span>
                      </span>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column label="单位" prop="unit"></el-table-column>
                <el-table-column
                  label="类型"
                  :show-overflow-tooltip="true"
                  prop="dataType"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- 因子数据图详情弹窗 -->
          <el-dialog custom-class="custom-dialog" :title="divisors.active.customDivisorName" append-to-body :close-on-click-modal="false" width="80%" top="15vh" :visible.sync="divisorData.visible">
            <div class="flex-column" style="height: calc(70vh - 80px);" v-loading="divisorData.loading">
              <Chart :option="divisorChart" class="flex-auto"></Chart>
            </div>
          </el-dialog>
        </el-col>
        <el-col :span="12">
          <div class="column flex-column" v-loading="verifyRate.loading">
            <div class="flex-row align-items-start justify-between">
              <div>
                <div>
                  <span>模型精度</span>
                </div>
                <div class="margin-top-5 font-size-12">
                  <span class="color-sub">
                    验证周期：<template v-if="verifyRate.data.startTime||verifyRate.data.endTime">{{verifyRate.data.startTime | dateFormat(this.q.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}至{{verifyRate.data.endTime | dateFormat(this.q.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}</template>
                  </span>
                </div>
              </div>
              <el-tooltip effect="dark" content="展示数据列表" placement="top">
                <div class="link-text"  @click="verifyRate.dataViewVisible=true">数据详情</div>
              </el-tooltip>
            </div>
            <div class="flex-row margin-top-20">
              <div style="margin-right:5%">
                <p class="color-sub">移动1期平均偏差率</p>
                <p class="font-size-20">{{verifyRate.data.step1DeviationRateAvg | numFormat(2,'-')}}%</p>
              </div>
            </div>
            <Chart :option="verifyRateChart" class="flex-auto"></Chart>
          </div>
          <el-dialog custom-class="custom-dialog" width="80%" :visible.sync="verifyRate.dataViewVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :append-to-body="true" top="50px">
            <div slot="title">
              <div class="flex-row align-items-center justify-between margin-right-40">
                <div class="el-dialog__title">模型精度-数据详情</div>
              </div>
            </div>
            <el-table
              :height="$root.docHeight-220"
              :data="verifyRate.data.dataList"
              size="medium"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="150"></el-empty></div>
              <el-table-column label="时间">
                <template slot-scope="props">{{props.row.dataCycle}}</template>
              </el-table-column>
              <el-table-column label="真实值">
                <template slot-scope="props">{{props.row.his | numFormat(2,'-')}}</template>
              </el-table-column>
              <el-table-column label="移动1期" align="center">
                <el-table-column label="验证值">
                  <template slot-scope="props">{{props.row.step1Value | numFormat(2,'-')}}</template>
                </el-table-column>
                <el-table-column label="偏差值">
                  <template slot-scope="props">{{props.row.step1DeviationValue | numFormat(2,'-')}}</template>
                </el-table-column>
                <el-table-column label="偏差率">
                  <template slot-scope="props">{{props.row.step1DeviationRate | numFormat(2,'-')}}{{ $numFormat(props.row.step1DeviationRate)?'%':'' }}</template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </el-dialog>
        </el-col>
        <el-col :span="12" v-if="q.modelFreq=='month'">
          <div class="column flex-column" v-loading="divisorContri.loading">
            <div class="flex-row align-items-start justify-between">
              <div>
                <div>
                  <span>因子脉冲响应</span>
                </div>
                <div class="margin-top-5 font-size-12">
                  <span class="color-sub">统计周期：36期</span>
                </div>
              </div>
              <div class="flex-auto flex-row justify-end">
                <el-select v-model="divisorPulse.activeId" size="mini" @change="getDivisorPulse" style="width:50%;min-width:200px;">
                  <el-option v-for="(item,index) in divisorPulse.divisorOptions" :key="index" :value="item.divisorId" :label="item.divisorName"></el-option>
                </el-select>
              </div>
            </div>
            <Chart :option="divisorPulseChart" class="flex-auto"></Chart>
          </div>
        </el-col>
        <el-col  :span="q.modelFreq=='month'?12:24">
          <div class="column flex-column" v-loading="divisorContri.loading">
            <div class="flex-row align-items-start justify-between">
              <div>
                <div><span>因子贡献度</span></div>
                <div class="margin-top-5 font-size-12">
                  <span class="color-sub">
                    统计周期：<template v-if="divisorContri.data.startTime||divisorContri.data.endTime">{{divisorContri.data.startTime | dateFormat(this.q.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}至{{divisorContri.data.endTime | dateFormat(this.q.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}</template>
                  </span>
                </div>
              </div>
              <!-- <el-tooltip effect="dark" content="展示数据列表" placement="top">
                <div class="link" @click="divisorContri.dataViewVisible=true">数据详情</div>
              </el-tooltip> -->
            </div>
            <div class="margin-top-10">
              <el-table
                :data="divisorContriSort"
                :show-header="false"
                max-height="300"
                size="medium"
                style="width: 100%">
                <div slot="empty"><el-empty :image-size="80"></el-empty></div>
                <el-table-column :show-overflow-tooltip="true" prop="customDivisorName"></el-table-column>
                <el-table-column>
                  <template slot-scope="props">
                    <el-progress v-if="divisorContri.data.dataList&&divisorContri.data.dataList.length" :stroke-width="5" :show-text="false" :color="divisorContri.data.dataList[divisorContri.data.dataList.length-1][props.row.customDivisorId]<0?'#11bf99':'#fd6864'" :percentage="Math.abs(divisorContri.data.dataList[divisorContri.data.dataList.length-1][props.row.customDivisorId]*100)"></el-progress>
                  </template>
                </el-table-column>
                <el-table-column align="right" width="100">
                  <template slot-scope="props">
                    <div v-if="divisorContri.data.dataList&&divisorContri.data.dataList.length">
                      {{$numFormat(parseFloat(divisorContri.data.dataList[divisorContri.data.dataList.length-1][props.row.customDivisorId])*100,2,'-')}}
                      {{$numFormat(divisorContri.data.dataList[divisorContri.data.dataList.length-1][props.row.customDivisorId])?'%':''}}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <el-dialog custom-class="custom-dialog" title="因子贡献度-数据视图"  :visible.sync="divisorContri.dataViewVisible" :append-to-body="true" width="80%" top="50px"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div slot="title">
                <div class="flex-row align-items-center justify-between margin-right-40">
                  <div class="el-dialog__title">因子贡献度-数据详情</div>
                </div>
              </div>
              <el-table v-if="divisorContri.data.items" :data="divisorContri.data.items"
                :height="$root.docHeight-220"
                size="medium" style="width: 100%"  @sort-change="divisorContriDataViewSort">
                <el-table-column fixed="left" :show-overflow-tooltip="true" prop="customDivisorName" min-width="200" label="因子"></el-table-column>
                <el-table-column  v-for="(item,index) in divisorContri.data.dataList" :key="index" :prop="item.dataCycle" :label="item.dataCycle" min-width="105" align="center" sortable="custom">
                  <template slot-scope="props">
                    <span>{{$numFormat(parseFloat(item[props.row.customDivisorId])*100,2,'-')}}</span>
                    {{$numFormat(item[props.row.customDivisorId])?'%':''}}
                  </template>
                </el-table-column>
              </el-table>
          </el-dialog>
        </el-col>
      </el-row>
      <el-dialog
        custom-class="custom-dialog"
        :append-to-body="true"
        top="30vh"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :visible.sync="progress.visible"
        width="40%"
        >
        <div slot="title">
          <div class="font-size-20">模型正在计算，预计需要{{(Math.round(progress.totalTime/60)||1)}}分钟，请稍候...</div>
          <div class="color-sub margin-top-10">温馨提示：刷新或关闭页面会中止运算，并将页面初始化。</div>
        </div>
        <Progress :totalSeconds="progress.totalTime" :finished="progress.finished" v-if="progress.visible" @cancel="cancelOperation"></Progress>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import crypto from "@/common/crypto";
import Progress from "@/components/Progress";
import progressTotalTime from "@/common/progressTotalTime";//进度条时间计算方法
import Chart from "@/components/Chart";
export default {
  name: 'CustomModelDetail',
  components:{
    Chart,Progress
  },
  props:{
    
  },
  data (){
    return{
      targetInfo:null,//目标价信息

      modelInfoLoading:false,
      modelInfo:{},//顶部模型信息

      modelForecast:{//模型预测
        loading:false,
        data:{},
        dataViewVisible:false,
      },
      trustGroup:{//预测分布
        loading:false,
        freq:1,
        data:{},
        dataViewVisible:false,
      },
      divisors:{//因子构成/因子赋值
        loading:false,
        data:[],
        active:{},
      },
      divisorData:{
        loading:false,
        visible:false,
        data:{}
      },

      verifyRate:{//模型精度
        loading:false,
        data:{},
        dataViewVisible:false,
      },
      divisorPulse:{//因子脉冲
        loading:false,
        divisorOptions:[],
        activeId:'',
        data:{},
      },
      divisorContri:{//因子贡献度
        loading:false,
        data:{},
        dataViewVisible:false,
      },
      runTimer:null,
      getRunIdLoading:false,
      dagRunId:"",
      progress:{
        visible:false,
        finished:true,
        totalTime:60,
      },
    }
  },
  computed:{
    q(){
      try {
        let q=JSON.parse(crypto.decrypt(this.$route.query.q))
        return q
      } catch (error) {
        return {modelId:157,modelFreq:'week'}
      }
    },
    //预测结果图表
    modelForecastChart(){
      let chartData=this.modelForecast.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.name=chartData.items[0].unit
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity:0
            }
          }
        })
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            id:v.id,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id=='his'?"solid":"dashed",
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='his'){
                if(!this.$numFormat(chartData.dataList[val.dataIndex]['his'])||!params.some(v=>v.seriesId=='his')){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
     //预测分布图表
    trustGroupChart(){
      let chartData=this.trustGroup.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.dataZoom.show=false
        opt.xAxis.type="value"
        opt.yAxis.type="category"
        opt.yAxis.data=chartData.dataList.map(v=>{return v.interval})
        opt.legend.data=chartData.items.filter(v=>{return v.id=='probability'}).map(v=>{
          return {
            name:v.name,
            unit:v.id=='probability'?'%':'',
            itemStyle:{
                opacity: v.id=='probability'?1:0
            }
          }
        })
        opt.series=chartData.items.filter(v=>{return v.id=='probability'}).map(v=>{
          return {
            name: v.name,
            type: 'bar', 
            barMaxWidth:20,
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
    //因子详情图表
    divisorChart(){
      let chartData=this.divisorData.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.name=this.divisors.active.unit
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:this.divisors.active.unit,
            itemStyle:{
                opacity:0
            }
          }
        })
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            id:v.id,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id=='his'?"solid":"dashed",
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='his'){
                if(!this.$numFormat(chartData.dataList[val.dataIndex]['his'])||!params.some(v=>v.seriesId=='his')){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
    //模型精度图表
    verifyRateChart(){
      let chartData=this.verifyRate.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = true
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.legend.data=chartData.items.filter(v=>{return v.id!='step1DeviationValue'&&v.id!='step3DeviationValue'&&v.id!='step4DeviationValue'&&v.id!='step12DeviationValue'}).map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity: v.id!='step1DeviationRate'&&v.id!='step3DeviationRate'&&v.id!='step4DeviationRate'&&v.id!='step12DeviationRate'?0:1
            }
          }
        })
        opt.yAxis=[
          {
            type: 'value',
            name:chartData.items.find(v=>{return v.id=='his'}).unit,
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine: {
              lineStyle: {
                type:'dashed'
              }
            },
            scale:true,
          },
          {
            type: 'value',
            name:'',
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine:{
              show:false
            },
            axisLabel:{
              formatter: '{value}%'
            },
            min:0,
            max:100
          }
        ]
        opt.series=chartData.items.filter(v=>{return v.id!='step1DeviationValue'&&v.id!='step3DeviationValue'&&v.id!='step4DeviationValue'&&v.id!='step12DeviationValue'}).map(v=>{
          let isLine=(v.id!='step1DeviationRate'&&v.id!='step3DeviationRate'&&v.id!='step4DeviationRate'&&v.id!='step12DeviationRate')
          return isLine? {
            name: v.name,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id!='his'?"dashed":"solid"
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }:{ 
            name: v.name,
            type: 'bar', 
            yAxisIndex:1,
            barMaxWidth:15,  
            // barGap:'-20%',
            itemStyle:{ 
              opacity:0.5
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit||""}</div>`
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
    //因子贡献度图表
    divisorContriChart(){
      let chartData=this.divisorContri.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.axisLabel.show=false;
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.tooltip.axisPointer={type: 'shadow'};
        opt.tooltip.formatter = (params)=>{
          let sortArr=params.sort((a,b)=>{return b.value-a.value})
          let str=`<div>${params[0].axisValue}</div><div style="width:600px;display:flex;justify-content: space-between;flex-wrap:wrap;font-size:14px">`
          sortArr.forEach((val)=>{
            if(this.$numFormat(val.data)){
              str+=`<div style="width:48%; display:flex;"><div style="flex:0 0 10px">${val.marker}</div><div style="word-break: break-word;white-space:normal;">${val.seriesName}：<span style="font-weight:bold">${this.$numFormat(val.value*100,2)}%</span></div></div>`
            }
          })
          return str+'</div>'
        }
        opt.legend.data=chartData.items.map(v=>{
          return v.customDivisorName
        })
        
        opt.dataZoom.left=40
        opt.dataZoom.right=40
        opt.series=chartData.items.map(v=>{
          return {
            name: v.customDivisorName,
            type: 'bar', 
            barWidth:"25%",
            barMaxWidth:50,
            barMinWidth:5,
            stack: 'total',
            emphasis: {
              focus: 'series',
            },
            data: chartData.dataList.map(vv=>{return vv[v.customDivisorId]}),
          }
        })
        return opt
      }else{
        return null
      }
    },
    //因子脉冲图表
    divisorPulseChart(){
      let chartData=this.divisorPulse.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.month})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            itemStyle:{
                opacity:0
            }
          }
        })
        let lineStyle=[{
                type:"solid",
                opacity:1
              },{
                type:"dashed",
                opacity:0.3
              }],
            areaStyle= [{
                color:"#999",
                opacity:0.1,
                origin: 'start',
              },{
                color:this.$store.state.theme=="light"?"#fff":"#1b202c",
                opacity:1,
                origin: 'start',
              }]
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            type: 'line', 
            connectNulls:true,
            lineStyle:v.id=='MValue'?lineStyle[0]:lineStyle[1],
            areaStyle:v.id=='HValue'?areaStyle[0]:v.id=='LValue'?areaStyle[1]:{opacity:0},
            z:v.id=='HValue'?-2:v.id=='LValue'?-1:1,
            showSymbol:false,
            emphasis:{
              disabled:true
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        
        return opt
      }else{
        return null
      }
    },
    divisorContriSort(){
      //因子贡献度当期排序
      if(this.divisorContri.data.items){
        let items=[...this.divisorContri.data.items],
            data=this.divisorContri.data.dataList[this.divisorContri.data.dataList.length-1]
        if(data){
          items = items.sort((a, b) => {
            return  data[b.customDivisorId]- data[a.customDivisorId]
          })
        }
        return items
      }else{
        return []
      }
      
    },
  },
  created(){
    
  },
  mounted(){
    this.getData()
  },
  destroyed(){
   this.killDag()
  },
  methods:{
    async getData(){
      if(this.q.modelId){
        this.getModelInfo()
        this.getTargetInfo()
        this.getModelForecast()
        this.getVerifyRate()
        this.getDivisorContri()
        this.getDivisors()
        if(this.q.modelFreq=='month'){
          this.getTrustGroupValue()
          await this.getDivisorOptions()
          this.divisorPulse.activeId&&this.getDivisorPulse()
        }
      }
    },
    //获取目标价信息
    getTargetInfo(){
      let request=this.$instance.get('/befarCustomModel/getCustomTargetInfo',{
          params: {
           ...this.$store.state.basicParams,
           modelId:this.q.modelId,
          },
      })
      request.then(res=>{
        if(res.data.code==0){
          this.targetInfo=res.data.info
        }else{
          this.targetInfo=null
        }
      })
      request.catch(()=>{
        this.targetInfo=null
      })
      return request
    },
    //获取顶部模型信息
    getModelInfo(){
      this.modelInfoLoading=true;
      let request=this.$instance.get('/befarCustomModel/getCustomModelInfo',{
          params: {
            ...this.$store.state.basicParams,
            modelId:this.q.modelId,
            modelFreq:this.q.modelFreq
          },
      })
      request.then(res=>{
        this.modelInfoLoading=false;
        if(res.data.code==0){
          this.modelInfo=res.data.info
        }
        else{
          this.modelInfo={}
        }
      })
      request.catch(()=> {
        this.modelInfoLoading=false;
        this.modelInfo={}
      }); 
      return request
    },
    //更新报告开关
    updateReportStatus(){
      let requestData={
        ...this.$store.state.basicParams,
           modelId:this.q.modelId,
           isReport:this.modelInfo.isReport
      }
      let request=this.$instance.post('/befarCustomModel/updateModelIsReport',requestData)
      request.then(res=>{
        if(res.data.code==0){
          this.$message({message:'设置成功',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }else{
          this.modelInfo.isReport==0?this.modelInfo.isReport=1:this.modelInfo.isReport=0
        }
      })
      request.catch(()=>{
        this.modelInfo.isReport==0?this.modelInfo.isReport=1:this.modelInfo.isReport=0
      })
      return request
    },
   //模型预测
    getModelForecast(){
      this.modelForecast.loading=true;
      this.$instance.get('/befarCustomModel/getTargetPredictValue',{
          params: {
            ...this.$store.state.basicParams,
            customModelId:this.q.modelId,
            modelFreq:this.q.modelFreq
          },
      })
      .then(res=>{
        this.modelForecast.loading=false
        if(res.data.code==0){
          this.modelForecast.data=res.data.info
        }
        else{
          this.modelForecast.data={}
        }
      })
      .catch(()=>{
        this.modelForecast.loading=false
        this.modelForecast.data={}
      })
    },
    //预测分布
    getTrustGroupValue(){
      this.trustGroup.loading=true;
      this.$instance.get('/befarCustomModel/getTrustGroup',{
          params: {
            ...this.$store.state.basicParams,
            customModelId:this.q.modelId,
            index:this.trustGroup.freq,
          },
      })
      .then(res=>{
        this.trustGroup.loading=false
        if(res.data.code==0){
          this.trustGroup.data=res.data.info
        }
        else{
          this.trustGroup.data={}
        }
      })
      .catch(()=>{
        this.trustGroup.loading=false
        this.trustGroup.data={}
      })
    },
    
    //获取模型因子
    getDivisors(){
      this.divisors.loading=true;
      let request=this.$instance.get('/befarCustomModel/getDivisorInfo',{
          params: {
            ...this.$store.state.basicParams,
            customModelId:this.q.modelId,
            modelFreq:this.q.modelFreq,
          },
      })
      request.then(res=>{
        this.divisors.loading=false
        if(res.data.code==0){
          this.divisors.data=res.data.info
          this.$nextTick(()=>{
            this.$refs.divisorsTable.doLayout()
          })
        }
        else{
          this.divisors.data={}
        }
      })
      request.catch(()=> {
        this.divisors.loading=false
        this.divisors.data={}
      }); 
      return request
    },
  //获取因子走势图详情
    getDivisorChart(divisor){
      this.divisors.active=divisor
      this.divisorData={
        loading:true,
        visible:true,
        data:{}
      }
      this.$instance.get('/befarCustomModel/getDivisorDetail',{
          params: {
            ...this.$store.state.basicParams,
            customModelId:this.q.modelId,
            divisorId:divisor.customDivisorId,
            modelFreq:this.q.modelFreq,
          },
      })
      .then(res=>{
        this.divisorData.loading=false;
        if(res.data.code==0){
          this.divisorData.data=res.data.info;
        }else{
          this.divisorData.data={}
        }
      })
      .catch(()=> {
        this.divisorData.loading=false;
        this.divisorData.data={}
      }); 
    },
    //模型精度
    getVerifyRate(){
      this.verifyRate.loading=true;
      this.$instance.get('/befarCustomModel/getBackVerify',{
          params: {
            ...this.$store.state.basicParams,
            customModelId:this.q.modelId,
            modelFreq:this.q.modelFreq,
          },
      })
      .then(res=>{
        this.verifyRate.loading=false
        if(res.data.code==0){
          this.verifyRate.data=res.data.info
        }
        else{
          this.verifyRate.data={}
        }
      })
      .catch(()=>{
        this.verifyRate.loading=false
        this.verifyRate.data={}
      })
    },
    //获取因子贡献度
    getDivisorContri(){
      this.divisorContri.loading=true;
      this.$instance.get('/befarCustomModel/getContribution',{
          params: {
            ...this.$store.state.basicParams,
            customModelId:this.q.modelId,
            modelFreq:this.q.modelFreq,
          },
      })
      .then(res=>{
        this.divisorContri.loading=false
        if(res.data.code==0){
          this.divisorContri.data=res.data.info
        }
        else{
          this.divisorContri.data={}
        }
      })
      .catch(()=>{
        this.divisorContri.loading=false
        this.divisorContri.data={}
      })
    },
    //获取因子脉冲筛选项
    getDivisorOptions(){
      this.divisorPulse.loading=true;
      let request=this.$instance.get('/befarCustomModel/getDivisorOptions',{
          params: {
            ...this.$store.state.basicParams,
            customModelId:this.q.modelId,
          },
      })
      request.then(res=>{
        this.divisorPulse.loading=false
        if(res.data.code==0){
          this.divisorPulse.divisorOptions=res.data.info
          this.divisorPulse.activeId=(res.data.info&&res.data.info.length)?res.data.info[0].divisorId:''
          !this.divisorPulse.activeId&&(this.divisorPulse.data={})
        }
        else{
          this.divisorPulse.divisorOptions=[]
          this.divisorPulse.activeId=''
          this.divisorPulse.data={}
        }
      })
      request.catch(()=> {
        this.divisorPulse.loading=false
        this.divisorPulse.divisorOptions=[]
        this.divisorPulse.activeId=''
        this.divisorPulse.data={}
      }); 
      return request
    },
    //获取因子脉冲
    getDivisorPulse(){
      this.divisorPulse.loading=true;
      this.$instance.get('/befarCustomModel/getDivisorPulse',{
          params: {
            ...this.$store.state.basicParams,
            customModelId:this.q.modelId,
            divisorId:this.divisorPulse.activeId||'',
          },
      })
      .then(res=>{
        this.divisorPulse.loading=false
        if(res.data.code==0){
          this.divisorPulse.data=res.data.info
        }
        else{
          this.divisorPulse.data={}
        }
      })
      .catch(()=>{
        this.divisorPulse.loading=false
        this.divisorPulse.data={}
      })
    },
    //因子贡献度数据视图列表排序
    divisorContriDataViewSort({order,prop}){
      let data=this.divisorContri.data.dataList.find(v=>v.dataCycle===prop)
      if(order=="ascending"){
        //升序
        this.divisorContri.data.items = this.divisorContri.data.items.sort((a, b) => {
          return  data[a.customDivisorId]- data[b.customDivisorId]
        })
      }else{
        this.divisorContri.data.items = this.divisorContri.data.items.sort((a, b) => {
          return  data[b.customDivisorId]- data[a.customDivisorId]
        })
      }
    },
    //模型推演
    getRunId(){
      let requestData={
        modelFreq:this.q.modelFreq,
        modelId:this.q.modelId,
      }
      this.$instance.post('/befarCustomModel/calcModel',requestData)
      .then(res=>{
        this.getRunIdLoading=false
        if(res.data.code==0){
          this.progress.totalTime=progressTotalTime(this.modelInfo.samplingNum,this.divisors.data.length+1)
          this.progress.finished=false
          this.progress.visible=true
          this.dagRunId=res.data.info.dagRunId
          this.publishId=res.data.info.publishId
          this.runModel()
        }
      })
      .catch(()=>{
        this.getRunIdLoading=false
      })
    },
    runModel(){
      this.$instance.get('/befarCustomModel/getTargetCustomValue',{
        params: {
          ...this.$store.state.basicParams,
          dagRunId:this.dagRunId,
          modelFreq:this.q.modelFreq,
          modelId:this.q.modelId,
          publishId:this.publishId,
          type:'uncond'
        },
      })
      .then(res=>{
        if(res.data.code==0){
          this.progress.finished=true
          setTimeout(()=>{this.progress.visible=false},1000)
          this.saveModel()
        }else if(res.data.code==1){
          this.$message.closeAll()
          this.runTimer=setTimeout(()=>{this.runModel()},3000)
        }else{
          this.progress.finished=true
          this.progress.visible=false
        }
      })
      .catch(()=>{
        this.progress.finished=true
          this.progress.visible=false
      })
    },
    saveModel(){
      this.$instance.get('/befarCustomModel/modifyModelName',{
          params: {
           ...this.$store.state.basicParams,
           modelId:this.q.modelId,
           modelName:this.modelInfo.customModelName,
           publishId:this.publishId,
          },
      })
      .then(res=>{
        if(res.data.code==0){
          this.getData()
        }
      })
      .catch(()=>{
      })
    },
    //中止运算
    killDag(){
      !this.progress.finished&&this.dagRunId&&this.cancelOperation()
      !this.progress.finished&&clearTimeout(this.runTimer)
    },
    cancelOperation(){
      this.$instance.get('/befarCustomModel/killDag',{
          params: {
            ...this.$store.state.basicParams,
            dagRunId:this.dagRunId,
            dagId:this.q.modelFreq=='week'?'befar_pf_custom_assess_week':'befar_pf_custom_assess_month'
          },
      })
      .then(res=>{
          if(res.data.code==0){
            this.progress.finished=true
            this.progress.visible=false;
            clearTimeout(this.runTimer)
          }
      })
      .catch(()=> {}); 
    },
    //跳转情景模拟页面
    gotoScene(){
      let q=crypto.encrypt({modelFreq:this.q.modelFreq,modelId:this.q.modelId,publishId:this.modelInfo.publishId,}),
          path='/customScene'
      this.$router.push({
        path: path,
        query:{q}
      })
    },
    //跳转历史页面
    gotoHistory(){
      let q=crypto.encrypt({modelFreq:this.q.modelFreq,modelId:this.q.modelId}),
          path='/forecastHistory'
      this.$router.push({
        path: path,
        query:{q}
      })
    },
    delModel(){
      this.$confirm(`您确认要删除${this.modelInfo.customModelName?'“'+this.modelInfo.customModelName+'”':''}模型么？”`, '删除模型提示', {
        customClass:"custom-msgbox",
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        let  requestData={
            ...this.$store.state.basicParams,
            modelId:this.q.modelId,
          }
          console.log(this.$qs.stringify(requestData))
        this.$instance.post('/befarCustomModel/delModel',requestData)
        .then(res=>{
          if(res.data.code==0){
            this.$router.go(-1)
          }
        })
        .catch(()=> {}); 
        
      })
      .catch(() => {});
    },
  },
  
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

 
</style>
<style>

</style>